import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import { Alert } from 'reactstrap';
import Divider from '../sections/akkadian/Divider';
import imgBanner from "../assets/image/akkadian/teamworking.jpg";
import { Helmet } from "react-helmet";
import { Link } from 'gatsby';
const AboutPage = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          theme: "light",
          buttonText: "Contact us"
        }}
        footerConfig={{
          theme: "light",
          page: "akkadian-home",
          style: "style2", //style1, style2
        }}
      >
        <Helmet>
          <title>Akkadian Health - GP Referrals | ADHD Assessments | Telehealth Australia</title>
		  <meta
                name="description"
                content="Easy and affordable ADHD Assessments via telehealth for GPs in Australia. Learn how to refer your patients to Akkadian Health."
            />
        </Helmet>
				  <style>{`
					table{
					 width:90%;
					}
					table, th, td {
					  border: 1px solid white;
					  border-collapse: collapse;
					  padding: 8px;
					  color: white;
					  margin-left: 5%
					}
					th, td {
					  background-color: #2fbfcf;
					}
				  `}</style>

        <div className="inner-banner">
          <Container>
		  
		  
		  
            <Row className="justify-content-center mt-md-3 pt-24 pt-lg-29">
              <Col lg="9" xl="8" className="mb-7 mb-lg-6">
                <div className="px-md-6 text-center mb-11 mb-lg-14">
                  <h1 className="title gr-text-2 mb-9 mb-lg-12">How to refer patients to Akkadian Health</h1>
				  <p className="gr-text-10 mb-7 mb-lg-10">
                    We accept referrals sent to us directly via:
				  </p>
				  <div style={{textAlign: "left"}}>
				  <ul style={{textAlign: "left"}}><strong>Email:</strong> <a
                        className={`gr-text-9 font-weight-bold hover-underline active text-primary`}
                        href="mailto:reception@akkadianhealth.com"
                      >
                        reception@akkadianhealth.com
                      </a></ul>
				  <ul style={{textAlign: "left"}}><strong>Fax:</strong> 08 5117 3206</ul>
				  <ul style={{textAlign: "left"}}><strong>Secure messages:</strong>
					  <p className="gr-text-12">Argus: akkadian_coreplus@argus.net.au or Healthlink: 'akkadian' </p>
                  </ul>
				  </div>
                </div>

				<div>
				<h3 className="gr-text-8">Valid Referrals</h3>
				<ul className="gr-text-12 mb-7 mb-lg-4">
					<li>Must contain <strong>sufficient patient medical history</strong> and presenting complaint information.</li>
					<li>Must include a <strong>specific request for an ADHD assessment</strong> where that is intended.</li>
					<li>Must be <strong>addressed to 'Akkadian Health' or one of our practitioners</strong>. For Medicare reasons we 
					cannot accept referrals made out to other clinicians or service providers.</li>
				</ul>
				<p className="gr-text-12 mb-7 mb-lg-4">
					Incomplete or incorrectly addressed referrals will be rejected and patients will not be offered the option to book an 
					appointment (we will let you and/or the patient know if this is the case). Reasons for referral rejections are listed below.
				</p>
				<p className="gr-text-12 mb-11 mb-lg-9">
					For Psychology services, a <strong>valid</strong> referral letter must be accompanied by a Mental Health 
					Treatment/Care Plan to access the Better Access iniative for Medicare rebates.
				</p>
				
				<h3 className="gr-text-8">We do not prescribe medication (unless the patient resides in SA)</h3>
				<p className="gr-text-12 mb-7 mb-lg-4">				
					If patients are diagnosed with ADHD, our comprehensive treatment plan guides the referring GP to prescribe 
					and manage any recommended medication. It is your responsibility to enact the treatment plan (which includes 
					initiating the recommended medication) and provide the necessary follow-up care. 
					Refer to our <Link to={`/faq#meds`} className={`gr-text-12`}> FAQ </Link>
					: "<i>What if I need medication?</i>" for more information.
				</p>
				<p className="gr-text-12 mb-11 mb-lg-9">
					For patients located in <strong><a href="/locations/south-australia" className={`gr-text-12`}>South Australia</a></strong>, Akkadian Health has established the <strong><a href="/adhd-care-centre-adelaide" className={`gr-text-12`}>Akkadian Health Care Centre (SA)</a></strong> staffed by expert clinicians who will prescribe, initiate and monitor 'stimulant' 
						medication for the treatment of ADHD under the guidance of Akkadian Health psychiatrists for patients 
						diagnosed by Akkadian Health. The Care Centre operates on Franklin Street in the Adelaide CBD for convenient 
						teleface-to-face consultations. Please contact the Akkadian Health Care Centre (SA) via email on care_sa@akkadianhealth.com OR 
						on 1800 290 880 (option #5).
				</p>
						  
				<h3 className="gr-text-8">We do not accept referrals from residents of WA or NSW for ADHD Assessments</h3>
				<p className="gr-text-12 mb-7 mb-lg-4">
					Every state in Australia has different health department regulations regarding the prescription of Schedule 
					8 medication used to treat ADHD. WA and NSW currently do not allow referring GPs to initiate medication. 
					If you are referring a patient from WA or NSW and they choose to proceed with an ADHD Assessment from 
					Akkadian Health, please ask the patient to contact us first and be aware that they will need to find a 
					local state-based psychiatrist to initiate medication treatment if they are diagnosed with ADHD.
				</p>
				
				</div>

              </Col>
			  
			</Row>
			
			<Row className="justify-content-center">
			  <Col lg="12" className="mb-7 mb-lg-6">
				<div className="text-center">
				<Alert color="warning">
				<h3 className="gr-text-10 px-3">PLEASE NOTE THAT WE DO NOT FACILITATE OR PROVIDE A CRISIS SERVICE.</h3>
				<hr />
				<p className="gr-text-12 px-3 mx-6">If patients seeking Akkadian Health services have attempted to harm themselves or others or are experiencing such thoughts with the intent to carry them out, crisis assessment and support teams are available 24 hours a day across Australia. In South Australia dial <a href="tel:131465"><strong>13 14 65</strong></a> for the Mental Health Triage Service. For other Australian states, visit <a href="https://www.healthdirect.gov.au/crisis-management" className="alert-link"> healthdirect/crisis-management</a> for crisis advice and service contact details. Alternatively, call Lifeline on <a href="tel:131114"><strong>13 11 14</strong></a> for support.
				</p>
				<p className="gr-text-12 px-6 mx-6">For life-threatening emergencies we recommend dialing <a href="tel:000"><strong>000</strong></a> immediately or supporting your patient to attend their nearest hospital emergency department (ED) or mental health crisis centre. 
				</p>
				<p className="gr-text-12">Our 'private' telehealth services are not appropriate for acute mental health conditions as imposed and unavoidable wait times may apply.</p> 
				</Alert>
				</div>	
				
				
			  </Col>
			</Row>			
			
			
			<Row >  		  
              <Col xs="2">
                <div className="banner-fluid-image pt-lg-6">
                </div>
              </Col>
              <Col xs="8">
                <div className="banner-fluid-image pt-lg-6">
                  <img src={imgBanner} alt="" className="d-flex text-center w-100 rounded-12" />
                </div>
              </Col>	
              <Col xs="2">
                <div className="banner-fluid-image pt-lg-6">
                </div>
              </Col>			  
            </Row>
          </Container>
        </div>
        <div className="about-content pt-lg-14 pt-8 pb-8 pb-lg-14">
          <Container>	  
		  
            <Row>
              <Col lg="4" className="mb-7 mb-lg-6">
                <div className="pr-xl-10">
                  <h2 className="title gr-text-5 mb-3">
                    Why refer to Akkadian Health?
                  </h2>
                  <p className="gr-text-10 mb-6">
                    <strong> High-quality end-to-end care for your patients.</strong> 
				  </p>
				  <p className="gr-text-10 mb-0">
					Referrals to Akkadian Health services offer comprehensive assessment and management plans (psychiatric assessments under MBS:291) and support for Mental Health Treatment Plans under the Better Access initiative (psychology).
                  </p>

                </div>
              </Col>
              <Col lg="8">
                <div className="pr-xl-13">
                  <h3 className="gr-text-8">Convenient</h3>
                  <p className="gr-text-10 mb-7 mb-lg-10">
                    Telehealth allows us to provide mental health services that are convenient and accessible to everyone.
                  </p>
                  <h3 className="gr-text-8">Flexible Payment Options</h3>
                  <p className="gr-text-10 mb-7 mb-lg-10">
                    Whilst we are not a bulk-bill clinic, our fees are highly competitive and we endeavour to minimise the out-of-pocket expenses for all patients. We now have flexible payment plans to ensure that all patients can access the services they need without the upfront expense.
                  </p>
                  <h3 className="gr-text-8">Optimised</h3>
                  <p className="gr-text-10 mb-7 mb-lg-10">
                    We offer industry best wait-times and quick turn-arounds on assessment reports and management plans.
                  </p>
                  <h3 className="gr-text-8">Easy</h3>
                  <p className="gr-text-10 mb-7 mb-lg-10">
                    We quickly process referrals and offer patients an online booking and payment system. 
				  </p>			  
                </div>
              </Col>
            </Row>


			<Row className="justify-content-center">
			  <Col lg="12" className="mb-7 mb-lg-6">
				<div>
					<h2 className="title gr-text-5 mb-3">Rejected Referrals</h2>
				</div>
				
				<div>			
				<h3 className="gr-text-8">There are several reasons why we might reject* a referral...</h3>
				<hr />
				<table className="gr-text-12">
				  <thead>
					<tr>
					  <th style={{color:'black'}}>Reason</th>
					  <th style={{color:'black'}}>Description</th>
					</tr>
				  </thead>
				  <tbody>
					<tr>
					  <td>Incomplete Information</td>
					  <td>Incorrectly addressed or incomplete referrals will be rejected and patients will not be offered the option to book an appointment until these matters are addressed.</td>
					</tr>
					<tr>
					  <td>High Risk</td>
					  <td>We cannot take over care of patients with recent ED admissions or suicidal ideation. 'Private' services are not appropriate for acute mental health conditions as wait times apply.</td>
					</tr>
					<tr>
					  <td>Unsupported Service</td>
					  <td>Requests for services that we do not currently support (e.g. Autism assessments, Eating disorder concerns, full neuropsych reports, ongoing 'treatment' by a psychiatrist).</td>
					</tr>
					<tr>
					  <td>Resident of WA or NSW </td>
					  <td>Requests for ADHD Assessments from residents of WA or NSW. Due to local state regulations in relation to the subsequent treatment / prescription of medication, we cannot provide adequate follow-up care for patients in WA or NSW diagnosed with ADHD. Please raise any concerns with your local Office of the Chief Psychiatrist or state health department.</td>
					</tr>
					<tr>
					  <td>Patient Age</td>
					  <td>Our psychiatrists will only see pateints over the age of 16 years. Our psychologists offer support for patients from the age of 12.</td>
					</tr>
				  </tbody>
				  <caption className="gr-text-13 px-7">*In some cases, referrals may be updated and re-submitted for approval and processing e.g. where 'incomplete information' is resolved.</caption>				  
				</table>
				</div>
			  </Col>
			</Row>		  

          </Container>
        </div>
        <Divider />
                <div className="text-center py-7 py-lg-6">
                  <p className="gr-text-8 mb-1">
						Please review our new <Link to={`/faq`} className={`gr-text-9`}> FAQ </Link> for more detailed information.
				  </p>
				 </div>
				 <div className="text-center">
				 <Link to={`/faq`}>
					<Button variant="warning" className="gr-hover-y w-80 w-sm-auto">FAQ</Button>
				 </Link>
				</div>
				
				
			
      </PageWrapper>
    </>
  );
};
export default AboutPage;
